import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import { Link } from "gatsby"
import CookieBar from "../components/cookieBar"
import ServiceMenu from "../components/services/servicesMenuHeader"

import swise from "../../assets/images/works/teasers/swise.png"
import lutheran from "../../assets/images/works/teasers/lutheran.png"
import sr from "../../assets/images/works/teasers/simple-risk.png"

import pf from "../../assets/images/works/teasers/pf.png"
import idday from "../../assets/images/works/teasers/idday.png"
import fs from "../../assets/images/works/teasers/frankfurt.png"

import actelion from "../../assets/images/works/teasers/actelion.png"
import ceibs from "../../assets/images/works/teasers/ceibs.png"
import ida from "../../assets/images/works/teasers/iron-deficiency.png"

import cva from "../../assets/images/works/teasers/cva.png"
import fw from "../../assets/images/works/teasers/flavorwiki.png"
import nuyu from "../../assets/images/works/teasers/nuyu.png"

import rf from "../../assets/images/works/teasers/roger-federer.png"
import qiio from "../../assets/images/works/teasers/qiio.png"
import c4y from "../../assets/images/works/teasers/car4you.png"

import usg from "../../assets/images/works/teasers/unisg.png"
import mf from "../../assets/images/works/teasers/cs-football.png"
import vp from "../../assets/images/works/teasers/vifor-pharma.png"

import saut from "../../assets/images/works/teasers/saut.png"
import sfi from "../../assets/images/works/teasers/sfi.png"
import wf from "../../assets/images/works/teasers/CS-football-women.png"

import ks from "../../assets/images/works/teasers/kanebo.png"
import sa from "../../assets/images/works/teasers/sigma-aldrich.png"
import zs from "../../assets/images/works/teasers/zollinger.png"

import residenz from "../../assets/images/works/teasers/residenz_forch.png"
import elkuch from "../../assets/images/works/teasers/elkuch.png"

export default () => {
    return (
        <div>
            <SEO title={'ICON Worldwide | Our Works'} 
            description="A portfolio of cutting-edge and successful nearshore software development works. At ICON Worldwide, we don’t follow agency rules – we make them!"
            canonical={'https://icon-worldwide.com/works'}/>
            <CookieBar/>
            <Header bgcolor="#292929"/>
            <div id="mobile-menu"><ServiceMenu bgcolor="#292929"/></div>
            <div id="work">
                <div id="workInner">
                    <h1>Our Latest Work<span></span></h1>

                    <Link to="/works/swise">
                        <div className="teaser">
                            <img src={swise} alt="Nearshore software development, ICON Worldwide, Swise" title="ICON Worldwide, Nearshore software development, Swise"/>
                            <div className="title">
                                <p>Swise DeFi</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/lutheran">
                        <div className="teaser">
                            <img src={lutheran} alt="Nearshore software development, ICON Worldwide, Lutheran World Federation" title="ICON Worldwide, Nearshore software development, Lutheran"/>
                            <div className="title">
                                <p>Lutheran World Federation</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/simple-risk">
                        <div className="teaser">
                            <img src={sr} alt="Nearshore software development, ICON Worldwide, SimpleRisk" title="ICON Worldwide, Nearshore software development, SimpleRisk"/>
                            <div className="title">
                                <p>SimpleRisk</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/project-firefly">
                        <div className="teaser">
                            <img src={pf} alt="Nearshore software development, ICON Worldwide, Project Firefly" title="ICON Worldwide, Nearshore software development, Project Firefly"/>
                            <div className="title">
                                <p>Project Firefly</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/iron-deficiency-day">
                        <div className="teaser">
                            <img src={idday} alt="Nearshore software development, ICON Worldwide, Iron Deficiency Day" title="ICON Worldwide, Nearshore software development, Iron Deficiency Day"/>
                            <div className="title">
                                <p>Iron Deficiency Day</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/frankfurt-school">
                        <div className="teaser">
                            <img src={fs} alt="Nearshore software development, ICON Worldwide, Frankfurt School" title="ICON Worldwide, Nearshore software development, Frankfurt School"/>
                            <div className="title">
                                <p>Frankfurt School</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/actelion">
                        <div className="teaser">
                            <img src={actelion} alt="Nearshore software development, ICON Worldwide, Actelion" title="ICON Worldwide, Nearshore software development, Actelion"/>
                            <div className="title">
                                <p>Actelion</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/ceibs">
                        <div className="teaser">
                            <img src={ceibs} alt="Nearshore software development, ICON Worldwide, CEIBS" title="ICON Worldwide, Nearshore software development, CEIBS"/>
                            <div className="title">
                                <p>CEIBS</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/iron-deficiency">
                        <div className="teaser">
                            <img src={ida} alt="Nearshore software development, ICON Worldwide, Iron Deficiency" title="ICON Worldwide, Nearshore software development, Iron Deficiency"/>
                            <div className="title">
                                <p>Iron Deficiency</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/elkuch">
                        <div className="teaser">
                            <img src={elkuch} alt="Nearshore software development, ICON Worldwide, Elkuch" title="ICON Worldwide, Nearshore software development, Elkuch"/>
                            <div className="title">
                                <p>Elkuch</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/crypto-valley-academy">
                        <div className="teaser">
                            <img src={cva} alt="Nearshore software development, ICON Worldwide, Crypto Valley Academy" title="ICON Worldwide, Nearshore software development, Crypto Valley Academy"/>
                            <div className="title">
                                <p>Crypto Valley Academy</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/flavorwiki">
                        <div className="teaser">
                            <img src={fw} alt="Nearshore software development, ICON Worldwide, FlavorWiki" title="ICON Worldwide, Nearshore software development, FlavorWiki"/>
                            <div className="title">
                                <p>FlavorWiki</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/nuyu">
                        <div className="teaser">
                            <img src={nuyu} alt="Nearshore software development, ICON Worldwide, NuYu" title="ICON Worldwide, Nearshore software development, NuYu"/>
                            <div className="title">
                                <p>NuYu</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/credit-suisse">
                        <div className="teaser">
                            <img src={rf} alt="Nearshore software development, ICON Worldwide, Credit Suisses" title="ICON Worldwide, Nearshore software development, Credit Suisses"/>
                            <div className="title">
                                <p>Credit Suisse</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/qiio">
                        <div className="teaser">
                            <img src={qiio} alt="Nearshore software development, ICON Worldwide, Qiio" title="ICON Worldwide, Nearshore software development, Qiio"/>
                            <div className="title">
                                <p>qiio</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/car4you">
                        <div className="teaser">
                            <img src={c4y} alt="Nearshore software development, ICON Worldwide, Car4You" title="ICON Worldwide, Nearshore software development, Car4You"/>
                            <div className="title">
                                <p>Car4You</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/university-of-st-gallen">
                        <div className="teaser">
                            <img src={usg} alt="Nearshore software development, ICON Worldwide, University of St.Gallen" title="ICON Worldwide, Nearshore software development, University of St.Gallen"/>
                            <div className="title">
                                <p>University of St.Gallen</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/credit-suisse-football">
                        <div className="teaser">
                            <img src={mf} alt="Nearshore software development, ICON Worldwide, Credit Suisse Football" title="ICON Worldwide, Nearshore software development, Credit Suisse Football"/>
                            <div className="title">
                                <p>Credit Suisse Football</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/vifor-pharma">
                        <div className="teaser">
                            <img src={vp} alt="Nearshore software development, ICON Worldwide, Vifor Pharma" title="ICON Worldwide, Nearshore software development, Vifor Pharma"/>
                            <div className="title">
                                <p>Vifor Pharma</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/saut">
                        <div className="teaser">
                            <img src={saut} alt="Nearshore software development, ICON Worldwide, Saut" title="ICON Worldwide, Nearshore software development, Saut"/>
                            <div className="title">
                                <p>Saut</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/swiss-finance-institute">
                        <div className="teaser">
                            <img src={sfi} alt="Nearshore software development, ICON Worldwide, Swiss Finance Institute" title="ICON Worldwide, Nearshore software development, Swiss Finance Institute"/>
                            <div className="title">
                                <p>Swiss Finance Institute</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/womens-football">
                        <div className="teaser">
                            <img src={wf} alt="Nearshore software development, ICON Worldwide, Credit Suisse" title="ICON Worldwide, Nearshore software development, Credit Suisse"/>
                            <div className="title">
                                <p>Credit Suisse</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/kanebo-sensai">
                        <div className="teaser">
                            <img src={ks} alt="Nearshore software development, ICON Worldwide, Kanebo Sensai" title="ICON Worldwide, Nearshore software development, Kanebo Sensai"/>
                            <div className="title">
                                <p>Kanebo Sensai</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/sigma-aldrich">
                        <div className="teaser">
                            <img src={sa} alt="Nearshore software development, ICON Worldwide, Sigma Aldrich" title="ICON Worldwide, Nearshore software development, Sigma Aldrich"/>
                            <div className="title">
                                <p>Sigma Aldrich</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/zollinger-stiftung">
                        <div className="teaser">
                            <img src={zs} alt="Nearshore software development, ICON Worldwide, Zollinger Stiftung" title="ICON Worldwide, Nearshore software development, Zollinger Stiftung"/>
                            <div className="title">
                                <p>Zollinger Stiftung</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/residenz-forch">
                        <div className="teaser">
                            <img src={residenz} alt="Nearshore software development, ICON Worldwide, Residenz Forch" title="ICON Worldwide, Nearshore software development, Residenz Forch"/>
                            <div className="title">
                                <p>Residenz Forch</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <Footer noScrollbar="true"/>
        </div>
    )
}